import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {GoogleSpreadsheet} from 'google-spreadsheet'
import './Home.css';

const SPREADSHEET_ID = "1se4crwGivrsbGZKTaxHmwMbYe3OLxPSPyhuiZtTz0ac";
const CLIENT_EMAIL = "memsdev@neon-citizen-357622.iam.gserviceaccount.com";
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDELBCdDnS2Hytr\nn9SvL6V0mJ/qjN5V0mlnlTZQXzirXo7ZaSTkat93C24UIdo0VswVHt4ACJIOBVML\nAHIQ6pt2fMVlpYpMdPWN82D7ft9/CUQ19jRAgzXgxxYJk6E83lvDuGy/WcHnvVze\nrjPMaRJ4nj6Vae8fxg79+LCNAKKI6qxQJSN7jqVgRtyMuE80m5yy1Z2cZ9AS07S8\nmHzykrg9QSQexS35F/pDfejjsJiLCqRuweU2qmPLraNaaQ+4qZDdaU+OBknhIZiI\nOGpbcizCKyi+wIS417NjjmLQiOF4Ebit0jMeJDYQNa4W8llGUTmhtWgSP7RMAzRV\nIjzXz90zAgMBAAECggEAG9F526O4pjjeUek84kjZ6CJajwrB3p6zZsZwiM4v/ZEv\npaH04tVK8hkzV3SDemeSki4p4gxVFdrob0d1ZXO3bwJ7hZIHfhcZ2TLZnmBb9ipM\nu3A58aoJomHo/JusQjLwBV2qQipKxn+CY2uFmeeOgH9ENbDrgXB3IMm604vzAIIz\nF59yRkItRQKXVQfw4U2QslazEyUAIXlma2FTgd3j/mddAOuqFOuuuadQtXhgK0OT\nA+GyLUvU87aBrE8U7MXjt6h4fyuI8vz3QtPiGoVAkfQ+UhCkrHAJIZvUiNdVM4/+\npw4eBiQkGdEXJuxl3zg+0xZxGKvYracYmH1rRSUYnQKBgQDuFvZgDzRgT6xra3M8\nF1FbLjWVPuW9Vt1cocHm8mgZDjGykJFyrvtECpH2HioGGsiomPgBhafNGrfM4HXy\nt/t0wnQOrJfZTQbReuxclWkNtTD9M0rVQCsnkbfEcd4rlWA2V+SfKMRbnQVhGO/F\ncIsRBEWkblOJRxxiCqqZctepXwKBgQDS7d7XUdc+7kHRCs/sZEAa7bdr9m2q7WSZ\nuczZ+qyBdBTDNUrUN2arTgjgJH0Xok1oXjuLDFbN2eUe1NTDyOP5/PSBY8diCJX0\n/aWbsaRFu8pGCSBMs9Zb+1n36uTjAkwUDadAIOSM1wK2d0tYopqX3Vmcr92ICt8h\nFZCIZb6YrQKBgQCIqaGODewwhEsh9bFVgsTuI54aJtpUTjIN48ObPvVFvIIGTzlN\nrOcJbU/PCA1zJ5iaPgPNS9y/vGBYN1c8lSK/KIbZjXQ0BSSuxXI6yoBcn+6YyAJi\nWczrj5b+KaYvOjTZvKW8PfhH5d+alme62xjYEa2v7qwAllLSa/D015i23QKBgD3d\nktyonm9QxnEfsLhu9vZu/rtlvejCVB1ah6DviyU0/Nn8iYQQ88YOzz+etCn4aqfP\n67ru1Y7u9+rBFd6Oz38FGFnTACnaxtDijG5L/hiN6df82UJmBNtZr5utg5CpndLL\n5mBCxYYhkiQefTT0DE+6lowEcP9z4Nn9TzUSUoLJAoGBAN5aggpWcv+a6OYWpU8a\nKxLT3aU68+CotmRtyywPO35uepjIgWzommnpRMQ9AEBuyN5hAVFSb5CHjEaKNnPK\nUVBknttTLPj+cgDIKzlOAwAnMavhm2QeBegK0eYGD6EJH6h8Jt1SVOHQiPuGTuta\nDCIO7PtNDkwri20xrmF4Btrv\n-----END PRIVATE KEY-----\n";

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

class Event extends React.Component { 

  constructor() {
    super();
    this.state = { 
      clickImg: "https://memsworld.b-cdn.net/NYC/click.png",
      arrowImg: "https://memsworld.b-cdn.net/lolla/arrow.png",
      audioPlaying: false,
      
      mems: []
    };

    
    
  }


  
 
  componentDidMount()
  {
    window.location.replace("#start")

    const sheetsToData = async () => {
      try {
        await doc.useServiceAccountAuth({
          //key: "AIzaSyCZlmooivp6d9prrTllhZNlGfzjYeq0tx4"
          client_email: CLIENT_EMAIL,
          private_key: PRIVATE_KEY,
        });

        // loads document properties and worksheets-
        await doc.loadInfo();

        var sheet = doc.sheetsById["0"];
        var row = sheet.getRows();
        
        var tempArray = []
        var tempDict = {}
        //rows for pages



        //manual adds bc lost access to account 
        tempDict = {}
        tempDict['image'] = "https://brokencrayons-inspiration.b-cdn.net/mems%20lolla%20repo/IMG_3033.webp"
        tempDict['name'] = "Cashmere"
        tempDict['description'] = "Her dragonfly necklace reminds her of her mom whenever she sees a dragonfly she also remembers her grandmother. 3 generations of beautiful"
        tempArray.push(tempDict)

        //manual adds bc lost access to account 
        tempDict = {}
        tempDict['image'] = "https://brokencrayons-inspiration.b-cdn.net/mems%20lolla%20repo/IMG_3034.webp"
        tempDict['name'] = "Sage and Michelle"
        tempDict['description'] = "Sage was performing in New York. He met michelle at a club she wasn't interested at first but he knew he had to keep trying. He lives in LA and she is from NY. They have been doing long distance for 6 months but they are making it work. She'll be moving to LA soon"
        tempArray.push(tempDict)





        row.then((value) => {
          for(var i = 0; i < value.length; i++)
          {
            tempDict = {}
            tempDict['image'] = (String(value[i]['_rawData'][0]))
            tempDict['name'] = (String(value[i]['_rawData'][1]))
            tempDict['description'] = (String(value[i]['_rawData'][2]))
            //console.log(String(value[i]['_rawData'][1]))
            //console.log(String(value[i]['_rawData'][2]))
            console.log(tempDict.description)
            tempArray.push(tempDict)

          }

          


          this.setState({mems: tempArray})
        
          //this.setState({imageArray: tempArray})
        }).then(() => {tempArray = []});
        
       
        
       
        
  

      } catch (e) {
          //error if google sheets quota reached
          alert("hmmm... the site failed to load, i'll reload automatically, exit this alert!" );
          
          /*setTimeout(() => {
            window.location.reload()
          }, 5000);*/
      }
    };

    sheetsToData()
   
  }


  componentDidUpdate( )
  { 

   

  }
    

  
  

  

  
  render()
  {  
    var classNames = [
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven"
    ]
    

    return (
      <div className="pageContainer" id='start'>  
        <div className='full-center'>
         <center>
           <img className='header' src="https://memsworld.b-cdn.net/lolla/logo_white.png" style={{opacity: .8}}></img>
           <br></br>
           <br></br>
           <img src={"https://memsworld.b-cdn.net/lolla/lollxmems2.png"} className='logo' style={{margin: 'solid red 3px'}}></img>
           <br></br>
           <br></br>
           <div className='specialDiv'>
           <img className='header' src="https://memsworld.b-cdn.net/lolla/desc_white_2.png" style={{opacity: .8}}></img>
            
           
           </div>
          </center>
        </div>
        <div className='under-center'>
          <br></br>
          <br></br>
          <br></br>
          <center>
          <img className='arrow' src={this.state.arrowImg}  onClick={e => {window.location.replace("/#event");}} style={{opacity: .8}}></img>
          </center>
        </div>
        <div className='rest-of-content' id="event">
          <center>
            <br></br>

            <h1 style={{opacity: '.8'}} className="textTo">text your <br></br>memories to<br></br>Diana and Eliana</h1>
            <br></br>
            <br></br>
            <h1 style={{opacity: '.8'}} className="number"><a href="tel:9292564804" style={{textDecoration: 'none', color: 'white'}}>929-256-4804</a></h1>
            <br></br>
            <br></br>
            
            <ul class="polaroids">
             
              <div className="container">
            {this.state.mems.map((dict, i) => (

            
              <div className='item'>
                <li >
                  <a title={dict.name !== 'undefined' ? dict.name : ''} style={{opacity: .85}} className={classNames[Math.floor(Math.random() * 5)]}>
                  <img alt="Roeland!" src={dict.image} style={{width: '100%'}}  />
                  </a>
                </li>
                {dict.description !== 'undefined' && <p style={{textAlign: 'left', width: '90%'}} onClick={(e) => {console.log(dict['description'])}}>{dict.description}</p>}
                <br></br>
                <br></br>
                <br></br>
              </div>
       
            ))}
            </div>
            </ul>
            
            
            <h1><a href="https://memnfts.com" target="_blank" style={{color: 'white', opacity: '.8', textDecoration: 'none'}}>mems.</a></h1>
            <br></br>
            <div>




</div>
            
         
          </center>
        
        </div>

       
      </div>
      )
    
    
          
      
  }
}
export default Event;
